import ApiBase from '@/api/base'
import { notify } from '@/helpers/notifyHelper'

class ApiEmployeeComments {
	static create (self) {
		const form = self.form
		const authHeader = ApiBase.authHeaders()
		self.http('post', `${ApiBase.baseUrl()}/employee-comments/create`, form, authHeader)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', self.trans(response.data.message))
		})
	}
	static update (self, id) {
		const form = self.form
		const authHeader = ApiBase.authHeaders()
		self.http('put', `${ApiBase.baseUrl()}/employee-comments/${id}`, form, authHeader)
			.then(response => {
				this.getAll(self)
				self.hideDialog()
				notify(self, 'success', self.trans(response.data.message))
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
	static getAll (self) {
		self.http('get', `${ApiBase.baseUrl()}/employee-comments/`)
			.then(response => {
				ApiBase.setToStore(null, 'setEmployeeComments', response.data)
			})
	}
	static _delete (self, id) {
		self.http('delete', `${ApiBase.baseUrl()}/employee-comments/delete/${id}`, null, ApiBase.authHeaders())
			.then(response => {
				notify(self, 'success', self.trans(response.data.message))
				this.getAll(self)
			})
			.catch(err => {
				notify(self, 'error', err)
			})
	}
}

export default ApiEmployeeComments
